.center {
  height: 100vh;
}

.sign-in {
  cursor: pointer;
  font-size: 26px !important;
  height: 80px;
  border-radius: 50px;
  background: white;
  max-width: 340px;
  margin: auto;
  transition: 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.sign-in:hover {
  transition: 0.3s ease-in-out;
  transform: translateY(-10px);
}

.old-woman-bg .overlay-dark {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}
.old-woman-bg img {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  object-fit: cover;
}

@media (min-width: 1030px) {
  .old-woman-bg img {
    object-fit: contain;
  }
}