.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

input:focus,
select:focus {
  outline: none;
}

/* Style the select element */
select {
  appearance: none;
  /* Remove default arrow */
  padding-right: 20px;
  /* Add space for custom arrow */
  /* Add your custom arrow */
  background-position: calc(100% - 30px) center;
  /* Position the arrow to the right */
  background-repeat: no-repeat;
  /* Ensure the arrow is not repeated */
}

/* Style the arrow image */
select::-ms-expand {
  display: none;
  /* Hide the default arrow in IE/Edge */
}

.bg-beige {
  background-color: #E9D8C8;
}

.bg-orange {
  background-color: #F58674;
}

.text-darkgrey {
  color: #5B6770;
}

.text-orange {
  color: #F58674;
}

.f-38 {
  font-size: 38px;
}

.f-36 {
  font-size: 36px;
}

.f-32 {
  font-size: 32px;
}

.f-28 {
  font-size: 28px;
}

.f-26 {
  font-size: 26px;
}

.f-24 {
  font-size: 24px;
}

.f-22 {
  font-size: 22px;
}

.br-49 {
  border-radius: 30px;
}

.pointer {
  cursor: pointer;
}

body {
  background-color: black;
}

@media (min-width: 1200px) {
  .logo img {
    width: 160px;
  }

  .f-32 {
    font-size: 26px;
  }

  .faceholder-svg {
    overflow: hidden;
    width: 40%;
    max-width: 320px;
  }
}
@media (min-width: 1700px) {
  .input input,
.input select {
    height: 80px !important;
    font-size: 24px;
  }

  .logo img {
    width: 200px;
  }

  .f-32 {
    font-size: 32px;
  }

  .sign-in {
    font-size: 28px;
    height: 60px;
    max-width: 235px;
  }

  .faceholder-svg {
    overflow: hidden;
    width: 40%;
    max-width: 450px;
  }
}
@media (max-width: 450px) {
  .f-38 {
    font-size: 26px;
  }

  .f-28 {
    font-size: 20px !important;
  }

  .sign-in {
    height: 60px !important;
    max-width: 230px !important;
    font-size: 22px !important;
  }

  .logo img {
    width: 200px;
  }

  .input select {
    background-size: 20px;
  }
}
select option {
  /* Change the background color of the options */
  background-color: #f9f9f9;
  /* Change the text color of the options */
  color: #333;
}