.center {
  height: 100vh;
}

.button-stars .btn-container .sign-in {
  font-size: 23px;
  height: 95px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
}
.button-stars .btn-container img {
  position: absolute;
  top: -60px;
  width: 100%;
  opacity: 0.2;
  filter: invert(1);
}

.next-btn {
  border-radius: 50px;
  height: 70px;
  width: 230px;
  background-color: #F58674;
  color: white;
  cursor: pointer;
}
.next-btn:hover {
  transition: 0.3s ease-in-out;
  transform: translateY(-10px);
}

.bottle-image-container-beauty {
  position: fixed;
  right: 0px;
  bottom: 100px;
}

#starshine {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.shine {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  overflow: hidden;
  z-index: 2;
  color: tansparent;
  -moz-opacity: 0;
  opacity: 0;
  animation: glitter 6s linear 0s infinite normal;
  -webkit-animation: glitter 6s linear 0s infinite normal;
  -moz-animation: glitter 8s linear 0s infinite normal;
  -ms-animation: glitter 8s linear 0s infinite normal;
  -o-animation: glitter 8s linear 0s infinite normal;
}

.shine.small {
  width: 20px;
  height: 20px;
}

.shine.medium {
  width: 30px;
  height: 30px;
}

.shine.large {
  width: 50px;
  height: 50px;
}

/*CSS3 keyframes for glittering effect*/
@keyframes glitter {
  0% {
    -webkit-transform: scale(0.3) rotate(0deg);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(1) rotate(360deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(0.3) rotate(720deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.3) rotate(0deg);
    opacity: 0;
  }
}
@media (max-width: 480px) {
  .f-22 {
    font-size: 18px;
  }

  .f-36 {
    font-size: 26px;
  }

  .bottle-image-container-beauty img {
    width: 130px;
  }
}
@media (max-width: 380px) {
  .f-22 {
    font-size: 16px;
  }

  .f-36 {
    font-size: 23px;
  }

  .bottle-image-container-beauty img {
    width: 110px;
  }
}