.center {
  height: 100vh;
}

.input {
  /* For textarea elements */
}
.input input,
.input select {
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  border: 1px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
}
.input input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.input textarea::placeholder {
  color: white;
}

.sign-in {
  cursor: pointer;
  font-size: 28px;
  height: 80px;
  border-radius: 50px;
  background: white;
  max-width: 290px;
  margin: auto;
  transition: 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.sign-in:hover {
  transition: 0.3s ease-in-out;
  transform: translateY(-10px);
}