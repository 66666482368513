.webcam-container {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.video-flip video {
  transform: scaleX(-1);
}

.faceholder {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 3;
}
.faceholder .faceholder-spinner,
.faceholder .countdown-faceholder {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.faceholder .faceholder-spinner span,
.faceholder .countdown-faceholder span {
  margin: auto;
}
.faceholder img,
.faceholder .faceholder-svg {
  overflow: hidden;
  width: 40%;
}

.bottom-text {
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  bottom: 0;
  padding-bottom: 40px;
}

.scanning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(9, 245, 249, 0.8)), linear-gradient(90deg, #ffffff33 1px, transparent 0, transparent 19px), linear-gradient(#ffffff33 1px, transparent 0, transparent 19px), linear-gradient(transparent, #E28A7F);
  background-size: 100% 1.5%, 10% 100%, 100% 10%, 100% 100%;
  background-repeat: no-repeat, repeat, repeat, no-repeat;
  background-position: 0 0, 0 0, 0 0, 0 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 1.5%, 0% 1.5%);
  animation: move 2s infinite linear;
  z-index: 4;
}

@keyframes move {
  to {
    background-position: 0 100%, 0 0, 0 0, 0 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
.start-cream {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
}

.bottle-image-container-cream {
  position: absolute;
  right: 80px;
  bottom: 48px;
}
.bottle-image-container-cream img {
  width: 150px;
}

.placements-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
}

.right-placement {
  position: absolute;
  right: 25%;
  top: 20%;
  z-index: 10;
  text-align: center;
}
.right-placement .placement {
  margin: auto;
  width: 55%;
  transition: 0.3s ease-in-out;
  transform: scale(1);
}
.right-placement .popup-text {
  position: absolute;
}
.right-placement.right .placement {
  transform: scale(0);
}

.left-placement {
  position: absolute;
  left: 25%;
  top: 20%;
  z-index: 10;
  text-align: center;
}
.left-placement .placement {
  margin: auto;
  width: 55%;
  transition: 0.3s ease-in-out;
  transform: scale(1);
}
.left-placement.left .placement {
  transform: scale(0);
}

.bottom-placement {
  position: absolute;
  left: 50%;
  bottom: 30%;
  transform: translateX(-50%);
  z-index: 10;
  text-align: center;
}
.bottom-placement .placement {
  margin: auto;
  width: 55%;
  transition: 0.3s ease-in-out;
  transform: scale(1);
}
.bottom-placement.bottom .placement {
  transform: scale(0);
}

.right-popup-text {
  position: absolute;
  top: 17%;
  right: 20%;
  transform: translateY(-150%);
  opacity: 0;
  transition: 0.6s ease-in-out;
}
.right-popup-text.right {
  transform: translateY(0);
  opacity: 1;
}

.left-popup-text {
  position: absolute;
  top: 17%;
  left: 20%;
  transform: translateY(-150%);
  opacity: 0;
  transition: 0.6s ease-in-out;
}
.left-popup-text.left {
  transform: translateY(0);
  opacity: 1;
}

.bottom-popup-text {
  position: absolute;
  bottom: 30%;
  left: 20%;
  transform: translateY(150%);
  opacity: 0;
  transition: 0.6s ease-in-out;
}
.bottom-popup-text.bottom {
  transform: translateY(0);
  opacity: 1;
}

.right-hand-reset {
  position: absolute;
  right: 35px;
  bottom: 295px;
  z-index: 10;
  transition: 0.3s ease-in-out;
}
.right-hand-reset.refill {
  transition: 0.3s ease-in-out;
  bottom: 400px;
}

.creme-dropper-outside {
  position: absolute;
  right: 445px;
  bottom: 562px;
  z-index: 11;
  transition: 0.3s ease-in-out;
}
.creme-dropper-outside img {
  transition: 0.3s ease-in-out;
  transform: scale(0) translateY(300px);
}
.creme-dropper-outside.refill {
  right: 147px;
  bottom: 442px;
  transition: 0.3s ease-in-out;
}
.creme-dropper-outside.refill img {
  transition: 0.3s ease-in-out;
  transform: scale(1.2) translateY(0px);
}

.creme-dropper-inside {
  position: absolute;
  z-index: 11;
  transition: 0.3s ease-in-out;
}
.creme-dropper-inside img {
  transition: 0.3s ease-in-out;
  transform: scale(0) translateY(300px);
}
.creme-dropper-inside.right {
  top: 15px;
  right: 15%;
}
.creme-dropper-inside.right img {
  transform: scaleX(-1.2) scaleY(1.2);
}
.creme-dropper-inside.left {
  top: 15px;
  left: 15%;
}
.creme-dropper-inside.left img {
  transform: scaleX(1.2) scaleY(1.2);
}
.creme-dropper-inside.bottom {
  bottom: 50%;
  left: 43%;
  transform: translateX(-50%);
}
.creme-dropper-inside.bottom img {
  transform: scaleX(1.2) scaleY(1.2);
}

.liquiddrop {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: inset 0 0 10px white, inset 0 0 20px whitesmoke;
}

.liquiddrop::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  margin-left: 60px;
  margin-top: 80px;
}

.liquiddrop.animate {
  animation: liquid 4s linear;
  animation-fill-mode: forwards;
}

@keyframes liquid {
  0% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.8);
    border-radius: 43% 57% 53% 47%/51% 55% 45% 49%;
  }
  40% {
    transform: scale(0.6);
    border-radius: 67% 33% 74% 26%/35% 38% 62% 65%;
  }
  50% {
    transform: scale(0.62);
  }
  60% {
    transform: scale(0.62);
    box-shadow: inset 0 0 10px white, inset 0 0 20px whitesmoke, 0px 0px 22.2px rgba(0, 0, 0, 0.056), 0px 0px 47.8px rgba(0, 0, 0, 0.076), 0px 0px 76.7px rgba(0, 0, 0, 0.086), 0px 0px 109.6px rgba(0, 0, 0, 0.093), 0px 0px 149.3px rgba(0, 0, 0, 0.098), 0px 0px 218px rgba(0, 0, 0, 0.1);
  }
  100% {
    transform: scale(0.62);
    box-shadow: inset 0 0 10px white, inset 0 0 20px whitesmoke, 0px 0px 22.2px rgba(0, 0, 0, 0.056), 0px 0px 47.8px rgba(0, 0, 0, 0.076), 0px 0px 76.7px rgba(0, 0, 0, 0.086), 0px 0px 109.6px rgba(0, 0, 0, 0.093), 0px 0px 149.3px rgba(0, 0, 0, 0.098), 0px 0px 218px rgba(0, 0, 0, 0.1);
  }
}
.smile {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: white;
}

@media (min-width: 1190px) {
  .right-hand-reset {
    right: 35px;
    bottom: 295px;
  }
  .right-hand-reset.refill {
    bottom: 400px;
  }
}
@media (max-width: 1025px) and (min-height: 1100px) {
  .right-hand-reset {
    right: 35px;
    bottom: 295px;
  }
  .right-hand-reset.refill {
    bottom: 400px;
  }
}
@media (max-width: 1024px) and (min-width: 769px) and (max-height: 1366px) and (min-height: 1181px) {
  .right-hand-reset {
    right: 35px;
    bottom: 295px;
  }
  .right-hand-reset.refill {
    bottom: 400px;
  }
}
@media (max-width: 768px) and (min-width: 481) and (max-height: 1024px) and (min-height: 990px) {
  .faceholder-svg {
    overflow: hidden;
    width: 40%;
    max-width: 450px;
  }

  .right-hand-reset {
    right: 35px;
    bottom: 295px;
  }
  .right-hand-reset.refill {
    bottom: 400px;
  }
}
@media (max-width: 480px) {
  .right-hand-reset {
    right: 0px;
    bottom: 210px;
  }
  .right-hand-reset.refill {
    bottom: 270px;
  }
  .right-hand-reset img {
    width: 100px;
  }

  .bottle-image-container-cream {
    position: absolute;
    right: -20px;
    bottom: 15px;
  }
  .bottle-image-container-cream img {
    width: 130px;
  }

  .f-32 {
    font-size: 18px;
  }

  .bottom-text .f-32 {
    padding-right: 110px !important;
  }
}