.webcam-container {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.video-flip video {
  transform: scaleX(-1);
}

.faceholder {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 3;
}
.faceholder .faceholder-spinner,
.faceholder .countdown-faceholder {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.faceholder .faceholder-spinner span,
.faceholder .countdown-faceholder span {
  margin: auto;
}
.faceholder img,
.faceholder .faceholder-svg {
  overflow: hidden;
  width: 40%;
}

.bottom-text-camera {
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  bottom: 0;
  padding-bottom: 30px;
  z-index: 4;
}

.scanning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(9, 245, 249, 0.8)), linear-gradient(90deg, #ffffff33 1px, transparent 0, transparent 19px), linear-gradient(#ffffff33 1px, transparent 0, transparent 19px), linear-gradient(transparent, #E28A7F);
  background-size: 100% 1.5%, 10% 100%, 100% 10%, 100% 100%;
  background-repeat: no-repeat, repeat, repeat, no-repeat;
  background-position: 0 0, 0 0, 0 0, 0 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 1.5%, 0% 1.5%);
  animation: move 2s infinite linear;
  z-index: 4;
}

@keyframes move {
  to {
    background-position: 0 100%, 0 0, 0 0, 0 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}